<template>
  <b-modal
    id="company-domestic-registration-modal"
    ref="company-domestic-registration-modal"
    header-class="no-borders"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @ok="submit"
  >
    <template slot="modal-title">
      Company Home State
      <i id="domestic-registration-help">
        <feather-icon type="help-circle" />
      </i>
    </template>
    <b-tooltip
      :target="`domestic-registration-help`"
      placement="top"
      :triggers="'hover focus'"
    >
      Your Home State is also known as your Domestic Registration.
    </b-tooltip>
    <div class="modal-body">
      <template v-if="formedElsewhere">
        In what state or jurisdiction was your company officially created?
      </template>
      <template v-else>
        Where would you like to start your business?
      </template>
    </div>
    <br>
    <company-domestic-registration-form
      class="pb-2"
      :company="company"
      :load-products="loadProducts"
      @changed="domesticRegistrationChanged"
    />
  </b-modal>
</template>

<script>
import CompanyDomesticRegistrationForm from './HireUs/CompanyDomesticRegistrationForm'
import FeatherIcon from '@/components/shared/FeatherIcon'
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'CompanyDomesticRegistrationModal',
  components: { CompanyDomesticRegistrationForm, FeatherIcon },
  props: {
    company: Object,
    loadProducts: Boolean,
  },
  computed: {
    ...mapGetters('companies', [
      'domesticRegistration',
      'checkoutDomesticJurisdiction',
      'formedElsewhere',
    ]),
  },
  methods: {
    ...mapActions('companies', [
      'updateDomesticRegistrationJurisdiction',
      'updateCheckoutDomesticJurisdiction',
    ]),
    domesticRegistrationChanged(oldHomeState, newHomeState) {
      this.oldHomeState = oldHomeState
      this.newHomeState = newHomeState
    },
    async submit() {
      if (this.newHomeState) {
          await this.updateDomesticRegistrationJurisdiction({
            companyId: this.company.id,
            jurisdictionId: this.newHomeState.id,
            loadProducts: this.loadProducts,
          })
      }

      this.$emit('domestic-registration-changed', this.oldHomeState, this.newHomeState)
      this.$refs['company-domestic-registration-modal'].hide()
    },
    show() {
      this.$refs['company-domestic-registration-modal'].show()
    },
  },
}
</script>
<style lang="scss">
  #company-domestic-registration-modal {
    .modal-content {
      max-width: 500px;
      margin: 0 auto;
    }
    .modal-body {
      padding: 0 10px;
      margin: 0;
    }
  }

</style>
