var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "company-domestic-registration-modal",
      attrs: {
        id: "company-domestic-registration-modal",
        "header-class": "no-borders",
        size: "lg",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { ok: _vm.submit },
    },
    [
      _c("template", { slot: "modal-title" }, [
        _vm._v("\n    Company Home State\n    "),
        _c(
          "i",
          { attrs: { id: "domestic-registration-help" } },
          [_c("feather-icon", { attrs: { type: "help-circle" } })],
          1
        ),
      ]),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: `domestic-registration-help`,
            placement: "top",
            triggers: "hover focus",
          },
        },
        [
          _vm._v(
            "\n    Your Home State is also known as your Domestic Registration.\n  "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _vm.formedElsewhere
            ? [
                _vm._v(
                  "\n      In what state or jurisdiction was your company officially created?\n    "
                ),
              ]
            : [
                _vm._v(
                  "\n      Where would you like to start your business?\n    "
                ),
              ],
        ],
        2
      ),
      _c("br"),
      _c("company-domestic-registration-form", {
        staticClass: "pb-2",
        attrs: { company: _vm.company, "load-products": _vm.loadProducts },
        on: { changed: _vm.domesticRegistrationChanged },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }